import React, { useState, useEffect } from 'react';


const NewColumnName = ({ param, updateOutput, selectedStatement }) => {

    const [newColName, setNewColName] = useState(selectedStatement && selectedStatement.output[param?.Name] ? selectedStatement.output[param?.Name] : "")
    
    useEffect(() => {
        
        if (selectedStatement && selectedStatement.output[param?.Name]){
            updateOutput(param.Name, selectedStatement.output[param?.Name])
        }
    }, [selectedStatement])

    const updateTable = (key, value) => {
        setNewColName(value);
        updateOutput(key, value);
    }

    return (
        <div id="new-colname-Add" className="form-group" style={{ "display": param.Visible ? "" : "none" }}>
            <label htmlFor="ColToConvert" className="form-label">{param.Description}:</label>
            <input type="text" className="form-control mb-3" value={newColName} onChange={e => updateTable(param.Name, e.target.value)}
                placeholder={param.Description} />
        </div>
    );
}

export default NewColumnName;