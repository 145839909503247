import React, { useEffect, useState } from 'react';
import { setStatement } from '../../Actions/Index';


const NumberInput = ({ param, updateOutput, selectedStatement }) => {
    const [number, setNumber] = useState(selectedStatement && selectedStatement.output[param?.Name] ? selectedStatement.output[param?.Name] : 0)
    useEffect(() => {
        if(selectedStatement && selectedStatement.output[param?.Name]){
            updateOutput(param.Name, selectedStatement.output[param?.Name])
        }
    }, [selectedStatement])
    const updateTable = (key, value) => {
        setNumber(value)
        updateOutput(key, value);
    }

    return (
        <div id="number-Add" className="form-group">
            <label htmlFor="number" className="form-label">{param.Name}:</label>
            <input id="number" value={number} onChange={e => updateTable(param.Name, e.target.value)} type="number"
                className="form-control mb-3" placeholder={param.Description} />
        </div>
    );
}

export default NumberInput;