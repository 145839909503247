import React, { useState, useEffect, useRef } from 'react';
import DropDownBox from 'devextreme-react/drop-down-box';
import DataGrid, {
    Selection, Paging, FilterRow, Scrolling,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';


const MultiSelect = ({ valueExpr, displayExpr, placeholder, columns, dataSource, allowMultipleSelection, selectedRowKeys, setSelectedRowKeys }) => {
    useEffect(() => {
        if ( !gridSource) { //dataSource.length &&
            var store = new CustomStore({
                loadMode: 'raw',
                key: valueExpr,
                load() {
                    return dataSource;
                },
            })
            setGridSource(store)
        }
    }, [dataSource])


    const firstRender = useRef(true);
    const dataGridRef = useRef(null);
    useEffect(() => {
        if (firstRender.current && selectedRowKeys.length) {
            firstRender.current = false;
            setSeletion(selectedRowKeys)
            return;
        }
    }, [selectedRowKeys]);


    const [gridSource, setGridSource] = useState(null)
    const [selection, setSeletion] = useState(selectedRowKeys)



    const syncDataGridSelection = (e) => {
        var value = e.value || [];
        setSeletion(value);
    }
    const dataGridOnSelectionChanged = (e) => {
        var values = (e.selectedRowKeys.length && e.selectedRowKeys) || [];
        allowMultipleSelection ? setSelectedRowKeys(values) : setSelectedRowKeys(values[0]);
        setSeletion(values);
    }
    const dataGridRender = () => {
        return (
            <DataGrid
                ref={dataGridRef}
                className= "custom-z-index"
                columnAutoWidth = {true}
                showColumnLines = {true}
                height={345}
                dataSource={gridSource}
                columns={columns}
                hoverStateEnabled={true}
                selectedRowKeys={selectedRowKeys}
                onSelectionChanged={dataGridOnSelectionChanged}>
                {/* <Selection mode="multiple" />
        <Scrolling mode="virtual" />
        <Paging enabled={true} pageSize={10} />
        <FilterRow visible={true} />

                dataSource={gridSource}
                columns={columns}
                // hoverStateEnabled={true}
                selectedRowKeys={selectedRowKeys}
                onSelectionChanged={dataGridOnSelectionChanged}
                height={300}
                searchPanel={true}
            > */}
                <Selection mode={allowMultipleSelection ? "multiple" : "single"} />
                <Scrolling mode="virtual" />
                <Paging enabled={false} />
                <FilterRow visible={true} />

            </DataGrid>
        );
    }
    return (
        <>
            {gridSource && <DropDownBox
                value={selection}
                valueExpr={valueExpr}
                deferRendering={false}
                displayExpr={displayExpr}
                placeholder={placeholder ? placeholder : "Select a value..."}
                showClearButton={true}
                dataSource={gridSource}
                onValueChanged={syncDataGridSelection}
                contentRender={dataGridRender}
            />}
        </>
    )
}

export default MultiSelect; 