const stack={
    statments:[],
    nodes:[],
    branches:[],
    File : ["pre.json"]
}

const StatementStack = (state = stack, action) => {
    if(action.type === "SET_STACK_STAT"){
        state.statements = action.stat;
    }
    else if(action.type === "SET_STACK_NODE")
    {
        state.nodes = action.stat;
    }
    else if(action.type === "SET_STACK_BRANCH")
    {
        state.branches = action.stat;
    }
    else if(action.type === "SET_STACK_FILENAME")
    {
        state.File = action.stat;
    }

    return state;
}

export default StatementStack;