import React, {useEffect} from 'react';


const Prefix = ({ param, updateOutput, selectedStatement }) => {
    
    useEffect(() => {
        
        if (selectedStatement && selectedStatement.output[param?.Name]){
            updateOutput(param.Name, selectedStatement.output[param?.Name])
        }
    }, [selectedStatement])
    useEffect(() => {
        updateTable(param.Name, param.Value)
    }, [param])

    const updateTable = (key, value) => {
        updateOutput(key, value);
    }

    return (
        <div id="Prefix" className="form-group" style={{ "display": param.Visible ? "" : "none" }}>
            <input type="text" className="form-control" value={param.Value} onChange={e => updateTable(param.Name, e.target.value)}
                placeholder={param.Description} />
        </div>
    );
}

export default Prefix;