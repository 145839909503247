import React from 'react';
import Diagram, {
    Nodes, Edges, AutoLayout, Toolbox, Group,
} from 'devextreme-react/diagram';
import ArrayStore from 'devextreme/data/array_store';


const RelationshipDiagram = ({ nodes, edges }) => {

    const flowNodesDataSource = new ArrayStore({
        key: 'id',
        data: nodes,
    });
    const flowEdgesDataSource = new ArrayStore({
        key: 'id',
        data: edges,
    });
    return (
        <>
            <Diagram id="relationship-diagram"  simpleView={true} readOnly = {true}>
                <Nodes dataSource={flowNodesDataSource} idExpr="id" typeExpr="type" textExpr="text">
                    <AutoLayout type="layered" />
                </Nodes>
                <Edges dataSource={flowEdgesDataSource} idExpr="id" textExpr="text" fromExpr="fromId" toExpr="toId" />
                <Toolbox>
                    <Group category="general" title="General" />
                </Toolbox>
            </Diagram>
        </>
    )

}


export default RelationshipDiagram;