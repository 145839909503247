import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import CasecadingOperationManager from "../Managers/CasecadingOperationManager"
import Operations from '../../Data/Operations.json';
import "../../Static/expression.css";
import CustomModal from "../Utils/CustomModel"


const ExpressionInput = ({ param, updateOutput, tableCols, variables, prefix, selectedStatement }) => {
    const [cursorStats, setCursorStats] = useState(null);
    const [cursorTouchingWord, setCursorTouchingWord] = useState(null);
    const [showDetails, setShowDetails] = useState(false);
    const [filteredTableCols, setFilteredTableCols] = useState([]);
    const [constantSuggestions, setConstantSuggestions] = useState([]);
    const [operation, setOperation] = useState(null);
    const [expressionInputValue, setExpressionInputValue] = useState(selectedStatement && selectedStatement.output[param?.Name] ? selectedStatement.output[param?.Name] : "");
    const expressionRef = useRef(null);
    const suggestionsRef = useRef(null);
    const suggestionDivRef = useRef(null);
    const suggestionTableRef = useRef(null);
    const Operators = ["+", "-", "*", "/", "%", ">", "<", ">=", "<=", "=="];
    const type_function = "function", type_variable = "variable", type_column = "column";


    useEffect(() => {
        if (selectedStatement && selectedStatement.output[param?.Name])
            updateTable(param.Name, selectedStatement.output[param?.Name])
    }, [selectedStatement])

    useEffect(() => {
        let list = [];
        tableCols.forEach(col => {
            list.push(
                {
                    name: col,
                    type: type_column,
                }
            )
        })
        variables.forEach(v => {
            list.push(
                {
                    name: v,
                    type: type_variable,
                }
            )
        });
        Operations.forEach(operation => {
            list.push(
                {
                    name: operation["Name"],
                    type: type_function,
                });
        });
        setFilteredTableCols(list)
        setConstantSuggestions(list)

    }, [tableCols])

    useEffect(() => {
        let list = [];
        tableCols.forEach(col => {
            list.push(
                {
                    name: col,
                    type: type_column,
                }
            )
        })
        variables.forEach(v => {
            list.push(
                {
                    name: v,
                    type: type_variable,
                }
            )
        })
        Operations.forEach(operation => {
            list.push(
                {
                    name: operation["Name"],
                    type: type_function,
                });
        });
        setFilteredTableCols(list)
        setConstantSuggestions(list)
    }, [variables])

    const updateTable = (key, value) => {
        updateOutput(key, value);
    }

    const focus = () => {
        setShowDetails(true);
    }
    const blur = () => {
        setTimeout(() => {
            setShowDetails(false);
        }, 200);
    }


    const InputEvent = (e) => {
        setExpressionInputValue(e.target.value);

        var cursor = getCursorPosition(e.target),
            val = e.target.value,
            currentWord = getWord(val, cursor.end),
            strLeft = val.substring(0, cursor.start);


        var matched = [];
        var text = e.target.value;
        constantSuggestions.forEach(function (data) {
            var dataName = data.name.toLowerCase(),
                pos = dataName.indexOf(currentWord.toLowerCase());

            if (pos !== -1) {
                matched.push(data);
            }
        });

        setFilteredTableCols(matched);
        if (matched.length || !val.length)
            setShowDetails(true);
        else
            setShowDetails(false);
    }

    const createGuid = () => {
        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }
        return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4()).toLowerCase();
    }

    //updates start 

    function getCursorPosition(el) {
        var start = 0, end = 0, normalizedValue, range, textInputRange, len, endRange;
        if (typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
            start = el.selectionStart;
            end = el.selectionEnd;
        } else {
            range = document.selection.createRange();

            if (range && range.parentElement() == el) {
                len = el.value.length;
                normalizedValue = el.value.replace(/\r\n/g, "\n");

                textInputRange = el.createTextRange();
                textInputRange.moveToBookmark(range.getBookmark());

                endRange = el.createTextRange();
                endRange.collapse(false);

                if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
                    start = end = len;
                } else {
                    start = -textInputRange.moveStart("character", -len);
                    start += normalizedValue.slice(0, start).split("\n").length - 1;

                    if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
                        end = len;
                    } else {
                        end = -textInputRange.moveEnd("character", -len);
                        end += normalizedValue.slice(0, end).split("\n").length - 1;
                    }
                }
            }
        }
        return {
            start: start,
            end: end
        };
    }

    function setCursorPosition(input, start, end) {
        if (arguments.length < 3) end = start;
        if ("selectionStart" in input) {
            setTimeout(function () {
                input.selectionStart = start;
                input.selectionEnd = end;
            }, 1);
        }
        else if (input.createTextRange) {
            var rng = input.createTextRange();
            rng.moveStart("character", start);
            rng.collapse();
            rng.moveEnd("character", end - start);
            rng.select();
        }
    }


    const getWord = (s, pos) => {
        const n = s.substring(pos).match(/^[a-zA-Z0-9-_]+/)
        const p = s.substring(0, pos).match(/[a-zA-Z0-9-_]+$/)
        if (!p && !n) return ''
        return (p || '') + (n || '')
    }
    const addToTextarea = (rowData) => {
        var val = expressionRef.current.value,
            valueToAdd = rowData.name,
            cursor = getCursorPosition(expressionRef.current);
        var currentWord = getWord(val, cursor.end);

        setCursorStats(cursor);
        setCursorTouchingWord(currentWord);

        if (rowData.type == type_function) {
            Operations.filter(obj => {
                if (obj.Name === valueToAdd) {
                    setOperation(obj);
                }
            });
            return;
        }
        var strLeft = val.substring(0, cursor.start - currentWord.length),
            strRight = val.substring(cursor.start);

        var newValue = strLeft + valueToAdd + strRight;

        setExpressionInputValue(newValue)
        setCursorPosition(expressionRef.current, strLeft.length + valueToAdd.length);

        expressionRef.current.focus();


    }

    const handleClose = (script) => {
        expressionRef.current.focus();
        if (script) {
            var valueToAdd = script,
                cursor = cursorStats,
                currentWord = cursorTouchingWord,
                val = expressionRef.current.value,
                strLeft = val.substring(0, cursor.start - currentWord.length),
                strRight = val.substring(cursor.start);

            var newValue = strLeft + valueToAdd + strRight;

            setExpressionInputValue(newValue)
            setCursorPosition(expressionRef.current, strLeft.length + valueToAdd.length);
        }
        console.log(script)
        setOperation(null)
    };
    const handleKeyPress = (e) => {
        if (showDetails) {
            if (e.keyCode == 40) //keydown
            {
                handleArrowKeyDown(e);
            }
            else if (e.keyCode == 38) //keyup
            {
                handleArrowKeyUp(e)
            }
            else if (e.keyCode == 13) {
                handleEnterKeyPress(e)
            }
        }
    }

    const handleArrowKeyDown = (e) => {
        e.preventDefault()
        var rows = document.getElementsByClassName('suggestion');
        if (rows.length) {
            var index = 0, indexFound = false;
            for (let i = 0; i < rows.length; i++) {
                if (rows[i].className.includes("active")) {
                    index = i;
                    indexFound = true;
                }
            }
            if (!indexFound) {
                rows[index].className = "suggestion active";
            }
            else if (rows.length - 1 > index) {
                rows[index].className = "suggestion";
                rows[index + 1].className = "suggestion active";
                var scrollTo = rows[index + 1].offsetTop;
                suggestionDivRef.current.scrollTop = scrollTo;
            }
        }
    }

    const handleArrowKeyUp = (e) => {
        e.preventDefault()
        var rows = document.getElementsByClassName('suggestion');
        if (rows.length) {
            var index = 0, indexFound = false;
            for (let i = 0; i < rows.length; i++) {
                if (rows[i].className.includes("active")) {
                    index = i;
                    indexFound = true;
                }
            }
            if (!indexFound) {
                rows[index].className = "suggestion active";
            }
            else if (index == 0) {
                rows[index].className = "suggestion active";
                suggestionDivRef.current.scrollTop = 0;
            }
            else if (index > 0) {
                rows[index].className = "suggestion";
                rows[index - 1].className = "suggestion active";
                var scrollTo = rows[index - 1].offsetTop;
                suggestionDivRef.current.scrollTop = scrollTo;
            }
        }
    }


    const handleEnterKeyPress = (e) => {
        var row = document.getElementsByClassName('suggestion active');
        if (row.length) {
            e.preventDefault();
            row[0].click();
        }
    }
    //update end




    return (
        <>
            <div id="col-name-Add" className="form-group">
                <label htmlFor="Expression" className="form-label">{param.Name}: ({prefix?.Value})</label>

                <textarea id="Expression" onKeyDown={(e) => handleKeyPress(e)} ref={expressionRef} value={expressionInputValue} onFocus={focus} onBlur={blur} onInput={InputEvent} onChange={e => updateTable(param.Name, e.target.value)}
                    className="form-control mb-3 ignore-hide-event" placeholder={param.Description} autoComplete="off" />
                {showDetails &&
                    <div ref={suggestionDivRef} className='table-wrapper-scroll-y my-custom-scrollbar'>
                        <table ref={suggestionTableRef} class="table table-bordered">
                            <thead>
                                <tr className=''>
                                    <th scope="col">Name</th>
                                    <th scope="col">Type</th>
                                </tr>
                            </thead>
                            <tbody ref={suggestionsRef}>
                                {filteredTableCols.map((row, index) => (
                                    <tr key={index + "expression"} className='suggestion' onClick={() => addToTextarea(row)}>
                                        <td onClick={() => addToTextarea(row)}>{row.name}</td>
                                        <td onClick={() => addToTextarea(row)}>{row.type}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                }

            </div>

            {/* <Modal show={operation !== null} onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton style={{ background: "rgba(171, 171, 171, 0.34)" }}>
                    <Modal.Title>Generate casecading operation ({operation?.Name}) for expression:</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ background: "rgba(171, 171, 171, 0.34)" }}>
                    {operation && <CasecadingOperationManager Operation={operation} handleClose={handleClose} selectedStatement={null} Interpret={() => { }} Run={() => { }} />}
                </Modal.Body>
            </Modal> */}


            <CustomModal show={operation !== null} onClose={handleClose} title={`Generate casecading operation (${operation?.Name}) for expression:`}>
                {operation && <CasecadingOperationManager Operation={operation} handleClose={handleClose} selectedStatement={null} Interpret={() => { }} Run={() => { }} />}

            </CustomModal>
        </>
    );
}

export default ExpressionInput;