import React, { useEffect, useState } from 'react';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/data/odata/store';
import DataGrid, { Paging, FilterRow, FilterPanel, FilterBuilderPopup, GroupPanel, StateStoring, Pager, Column, Export } from 'devextreme-react/data-grid';

const createGuid = () => {
    function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4()).toLowerCase();
}
const filterBuilderPopupPosition = {
    of: window,
    at: 'top',
    my: 'top',
    offset: { y: 10 },
};
function gridCellData(gridData) {
    return gridData.data[gridData.column.caption];
}
const svgTemplate = (template) => {
    return (
        <div dangerouslySetInnerHTML={{ __html: gridCellData(template) }} />
    )
}
const Grid = ({ key, columns, dataSource, showSvg, tableName, enableExport }) => {
    const dataGrid = React.createRef();
    const [uniqueId, setUniqueId] = useState(createGuid());

    return (

        <DataGrid
            id={uniqueId}
            ref={dataGrid}
            columnChooser={{
                enabled: true,
                mode: "dragAndDrop"
            }}
            filterPanel={{ visible: true }}
            rowAlternationEnabled={true}
            allowColumnResizing={true}
            allowColumnReordering={true}
            columnAutoWidth={true}
            dataSource={dataSource}
            defaultColumns={columns}
            showBorders={true}
        >
            <FilterRow visible={true} />
            <FilterPanel visible={true} />
            <FilterBuilderPopup position={filterBuilderPopupPosition} />
            <GroupPanel visible={true} />
            <Export enabled={enableExport} fileName={tableName ?? "Data"} />
            {showSvg && <Column caption="Name" cellRender={svgTemplate} />}
            {showSvg && <Column caption="SVG" cellRender={svgTemplate} />}
        </DataGrid>
    );
}

export default Grid;