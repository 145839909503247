import React from 'react';
import "../../Static/CustomModel.css";

const CustomModal = ({ show, onClose, children, title }) => {
    if (!show) return null;
    return (
        <div className="custom-modal-overlay">
            <div className="custom-modal">
                <div className='custom-modal-header'>

                    <h2 className="custom-model-title">{title}</h2>
                    <button className="close-button" onClick={onClose}>
                        X
                    </button>
                </div>
                <div className='custom-modal-body'>
                    <div className="custom-modal-content">{children}</div>
                </div>
            </div>
        </div>
    );
};

export default CustomModal;