import React, { useEffect, useState } from 'react';
import SimpleDropdown from '../DevextremeComponents/DevExtremeSelectBox';


const SelectTable = ({ param, tables, updateOutput, setSelectedTblCols, selectedStatement }) => {

    const [initValue, setInitValue] = useState(null);
    useEffect(() => {
        if (tables.length) {
            if (selectedStatement != null && selectedStatement.output[param?.Name]) {
                let tableName = tables.find(x => x == selectedStatement.output[param?.Name])
                updateOutput(param.Name, tableName)
                setSelectedTblCols(tableName)
                setInitValue(tableName)
            }
            else {
                updateOutput(param.Name, tables[0])
                setSelectedTblCols(tables[0])
                setInitValue(tables[0])
            }
        }

    }, [tables])

    const updateTable = (key, value) => {
        setInitValue(value)
        updateOutput(key, value);
        setSelectedTblCols(value)
    }

    const setData = (tableName) => {
        updateOutput(param.Name, tableName)
        setSelectedTblCols(tableName)
        setInitValue(tableName)
    }
    return (
        <>
            <div id="tbl-selection-Add" className="form-group" style={{ "display": param.Visible ? "" : "none" }}>
                <label htmlFor="SelectTable" className="form-label">{param.Name} :</label>
                {/* <select id="SelectTable" onChange={e => updateTable(param.Name, e.target.value)}
                    className="form-select form-select mb-3"
                    aria-label=".form-select-lg example" title={param.Description} >
                    {tables.map((table, i) => (
                        <>
                            {(initValue === table) && <option key={i} value={table} selected > {table} </option>}
                            {(initValue !== table) && <option key={i} value={table}> {table} </option>}
                        </>
                    ))}
                </select> */}
                <SimpleDropdown dataSource={tables} selectedItem={initValue} setData={setData}></SimpleDropdown>
            </div>
        </>
    );
}

export default SelectTable;