import React, {useEffect} from 'react';


const NewSourceName = ({ newSrcName, setNewSrcName, selectedStatement }) => {
    useEffect(() => {
        if(selectedStatement && selectedStatement.newSourceName){
            setNewSrcName(selectedStatement.newSourceName)
        }
    }, [selectedStatement])

    return (
        <>
            <div id="NewSourceName" className="form-group">
                <label htmlFor="newSourceName" className="form-label">New Source Name:</label>
                <input type="text" id="newSourceName" className="form-control mb-3" value={newSrcName}
                    onChange={e => setNewSrcName(e.target.value)} placeholder="New source name here." />
            </div>
        </>
    );
}

export default NewSourceName;