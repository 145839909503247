import React, { useEffect, useState } from 'react';
import MultiSelect from '../DevextremeComponents/DevExtremeMultiSelect';


const ColumnList = ({ param, tableCols, updateOutput, selectedStatement }) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [renderMultiselect, setRenderMultiselect] = useState(false);

    const [key, setKey] = useState("Key");
    const [columns, setColumns] = useState(["Key"]);

    useEffect(() => {
        setRenderMultiselect(false);
    }, [tableCols])

    useEffect(() => {
        if (!renderMultiselect) {

            // if (!dataSource.length) {
                if (selectedStatement && selectedStatement.output[param?.Name]) {
                    var tempArray = [];
                    for (let i = 0; i < selectedStatement.output[param?.Name].length; i++) {
                        if (tableCols.includes(selectedStatement.output[param?.Name][i])) {
                            tempArray.push(selectedStatement.output[param?.Name][i]);
                        }
                    }
                    setSelectedRowKeys(tempArray);
                    updateOutput(param.Name, tempArray)
                }
                var source = [];
                for (var i = 0; i < tableCols.length; i++) {
                    source.push({ Key: tableCols[i] });
                }
                setDataSource(source);
                setRenderMultiselect(true);
            // }
        }
    }, [renderMultiselect])

    function getSelectValues(select) {
        var result = [];
        var options = select && select.options;
        var opt;
        for (var i = 0, iLen = options.length; i < iLen; i++) {
            opt = options[i];
            if (opt.selected) {
                result.push(opt.value || opt.text);
            }
        }
        return result;
    }

    const setCols = (cols) => {
        updateOutput(param.Name, cols)
    }

    return (
        <div id="ColList-Add" className="form-group">
            <label htmlFor="ColumnList" className="form-label">{param.Name}:</label>
            {/* <select onChange={e => updateOutput(param.Name, getSelectValues(e.target))}
                className="form-select form-select mb-3 selectpicker" multiple
                aria-label="size 3 select example" title={param.Description} >
                {tableCols.map((colName, i) => (
                    <>
                        {(selectedStatement && selectedStatement.output["Columns"].includes(colName)) && <option key={i} value={colName} selected> {colName} </option>}
                        {(!selectedStatement || !selectedStatement.output["Columns"].includes(colName)) && <option key={i} value={colName}> {colName} </option>}
                    </>
                ))}
            </select> */}
            {renderMultiselect && <MultiSelect valueExpr={key} displayExpr={key} column={columns} dataSource={dataSource} allowMultipleSelection={true} selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setCols} ></MultiSelect>}
        </div>
    );
}

export default ColumnList;