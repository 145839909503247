import react, { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getEnvironment, getWorkingEnvironment, getConfig, setFileName } from "./Actions/Index"
import Review from "./Components/DataReviewMain/Review"
import Visualizer from './Components/DataVisualizer/Visualizer';
import AppStateProvider from "./ContextApi/AppStateProvider"
import AppStateContext from './ContextApi/AppState';
import { readJsonFromPresignedUrl, getRequest } from "./Services/HttpService"
import { propTypes } from "react-bootstrap/esm/Image";
import { json } from "react-router-dom";

import MultiSelect from './Components/DevextremeComponents/DevExtremeMultiSelect';

var completeExpression = "";
var expression = "";

const Components = () => {
  const dispatch = useDispatch();

  const StoreData = useSelector((state) => state);
  // const AllBranches = useSelector((state) => state.getBranches);
  const FileName = useSelector((state) => state.getFileName);
  // const redo_stack = useSelector((state) => state.RedoStack);
  const statement_stack = useSelector((state) => state.StatementStack);

  const [IsDataSet, setIsDataSet] = useState(false);
  const [IsResumed, setIsResumed] = useState(false);

  const [visualizerInitialized, setVisualizerInitialized] = useState(false);
  useEffect(() => {
  
    //getting query string params from url..
    var queryStrings = (function (a) {
      if (a == "") return {};
      var b = {};
      for (var i = 0; i < a.length; ++i) {
        var p = a[i].split('=', 2);
        if (p.length == 1)
          b[p[0]] = "";
        else
          b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
      }
      return b;
    })(window.location.search.substr(1).split('&'));


    if (queryStrings?.token) {
      localStorage.setItem("token", queryStrings?.token);
    }
    if (queryStrings?.token2) {
      localStorage.setItem("jwt", queryStrings?.token2);
    }
    if (queryStrings?.fileKey && !FileName.length && (!queryStrings?.processedFileKey || queryStrings?.processedFileKey.toLowerCase() == 'undefined'|| queryStrings?.processedFileKey.toLowerCase() == 'null')) {

      window.isStartingEnvironment = true;
      let FileNames = [...statement_stack.File, queryStrings?.fileKey];
      dispatch({ type: "SET_STACK_FILENAME", stat: FileNames })
      dispatch({ ...setFileName(), fileName: queryStrings?.fileKey })
    }
    if (queryStrings?.domainName) {
      localStorage.setItem("domain", queryStrings?.domainName);
    }
    if (queryStrings?.storePreSignedUrl&& queryStrings?.storePreSignedUrl.toLowerCase() != "null" && queryStrings?.storePreSignedUrl.toLowerCase() != "undefined" && !IsResumed) {
      // var seedEnv = await getRequest("/DataReview/Describe?link=" + FileName).then((result) => {
      //   dispatch({ ...getEnvironment(), envData: result.data });
      // })
      // if (FileName.length && !Object.keys(Environment).length) {
      //   getRequest("/DataReview/Describe?link=" + FileName).then((result) => {
      //     dispatch({ ...getEnvironment(), envData: result.data });
      //   })
      // }
      // else if (FileName.length && Object.keys(Environment).length) {
      //   getRequest("/DataReview/Describe?link=" + FileName).then((result) => {
      //     dispatch({ ...getWorkingEnvironment(), envData: result.data });
      //   })
      // }
      readJsonFromPresignedUrl(queryStrings?.storePreSignedUrl).then((resumeStoreData) => {
        console.log(resumeStoreData);
        dispatch({ type: "SET_REDO_STACK_STAT", stat: resumeStoreData.RedoStack.statments })
        dispatch({ type: "SET_REDO_STACK_NODE", stat: resumeStoreData.RedoStack.nodes })
        dispatch({ type: "SET_REDO_STACK_BRANCH", stat: resumeStoreData.RedoStack.branches })
        dispatch({ type: "SET_REDO_STACK_FILENAME", stat: resumeStoreData.RedoStack.File })
        //undo stack;
        dispatch({ type: "SET_STACK_STAT", stat: resumeStoreData.StatementStack.statements })
        dispatch({ type: "SET_STACK_NODE", stat: resumeStoreData.StatementStack.nodes })
        dispatch({ type: "SET_STACK_BRANCH", stat: resumeStoreData.StatementStack.branches })
        dispatch({ type: "SET_STACK_FILENAME", stat: resumeStoreData.StatementStack.File })
        //Branches;
        dispatch({ type: "SET_BRANCHES", branches: resumeStoreData.getBranches })
        //getconfig not required here

        //GET_ENVIRONMENT;
        dispatch({ type: "GET_ENVIRONMENT", envData: resumeStoreData.getEnvironment })


        if (queryStrings?.processedFileKey && queryStrings?.processedFileKey.toLowerCase() != 'null' && queryStrings?.processedFileKey.toLowerCase() != 'undefined') {

          window.isStartingEnvironment = false;
          dispatch({ ...setFileName(), fileName: queryStrings?.processedFileKey })

        }


        //SET_FILENAME;
        // dispatch({ type: "SET_FILENAME", fileName: resumeStoreData.getFileName })
        //GET_WORKING_ENVIRONMENT;
        // dispatch({ type: "GET_WORKING_ENVIRONMENT", envData: resumeStoreData.getWorkingEnvironment })
        //SET_GRAPH_OBJECTS;
        dispatch({ type: "SET_GRAPH_OBJECTS", graphData: resumeStoreData.setGraphData })
        //SET_NODES;
        dispatch({ type: "SET_NODES", nodes: resumeStoreData.setNodes })
        //SET_STATEMENTS;
        dispatch({ type: "SET_STATEMENTS", statements: resumeStoreData.setStatement })
        setIsResumed(true);
        
      })

      // const resumeStoreData = await readJsonFromPresignedUrl(queryStrings?.storePreSignedUrl);

    }

    //resetting store after renewing session
    var previousStoreData = sessionStorage.getItem("StudyGen_DataReviewState");
    if (previousStoreData) {
      previousStoreData = JSON.parse(previousStoreData);

      //redo stack;
      dispatch({ type: "SET_REDO_STACK_STAT", stat: previousStoreData.RedoStack.statements })
      dispatch({ type: "SET_REDO_STACK_NODE", stat: previousStoreData.RedoStack.nodes })
      dispatch({ type: "SET_REDO_STACK_BRANCH", stat: previousStoreData.RedoStack.branches })
      dispatch({ type: "SET_REDO_STACK_FILENAME", stat: previousStoreData.RedoStack.File })
      //undo stack;
      dispatch({ type: "SET_STACK_STAT", stat: previousStoreData.StatementStack.statements })
      dispatch({ type: "SET_STACK_NODE", stat: previousStoreData.StatementStack.nodes })
      dispatch({ type: "SET_STACK_BRANCH", stat: previousStoreData.StatementStack.branches })
      dispatch({ type: "SET_STACK_FILENAME", stat: previousStoreData.StatementStack.File })
      //Branches;
      dispatch({ type: "SET_BRANCHES", branches: previousStoreData.getBranches })
      //getconfig not required here

      //GET_ENVIRONMENT;
      dispatch({ type: "GET_ENVIRONMENT", envData: previousStoreData.getEnvironment })
      //SET_FILENAME;
      dispatch({ type: "SET_FILENAME", fileName: previousStoreData.getFileName })
      //GET_WORKING_ENVIRONMENT;
      dispatch({ type: "GET_WORKING_ENVIRONMENT", envData: previousStoreData.getWorkingEnvironment })
      //SET_GRAPH_OBJECTS;
      dispatch({ type: "SET_GRAPH_OBJECTS", graphData: previousStoreData.setGraphData })
      //SET_NODES;
      dispatch({ type: "SET_NODES", nodes: previousStoreData.setNodes })
      //SET_STATEMENTS;
      dispatch({ type: "SET_STATEMENTS", statements: previousStoreData.setStatement })
      sessionStorage.removeItem("StudyGen_DataReviewState")
    }

  }, [])
  const SaveStoreToLocalHost = () => {
    sessionStorage.setItem("StudyGen_DataReviewState", JSON.stringify(StoreData))
  }
  return (


    <AppStateContext.Consumer>
      {context => (<>

        {context.showReview && <Review />}
        {!context.showReview && <Visualizer visualizerInitialized={visualizerInitialized} setVisualizerInitialized={setVisualizerInitialized} />}
        <button style={{ display: "none" }} onClick={SaveStoreToLocalHost} id="SaveStoreToLocalHost">Store To Local</button>
      </>
      )}
    </AppStateContext.Consumer>


  )
}

function App() {


  return (
    <>
      <AppStateProvider>
        <Components></Components>
      </AppStateProvider>
      {/* <MultiSelect valueExpr = {"key"} displayExpr = {"key"} column = {["first", "second", "third", "fourth", "fifth", "sixth"]} dataSource = {[{ key: "first" }, { key: "second" }, { key: "third" }, { key: "fourth" }, { key: "fifth" }, { key: "sixth" }]} allowMultipleSelection = {true} selectedRowKeys = {["first", "second", "sixth"]} setSelectedRowKeys = {() =>{}}></MultiSelect> */}
    </>
  );
}

export default App;
