import React from 'react'
import "../../Static/Display.css";
const Display = ({ func, onFuncSelect, tab, isActive = false, icon = "" }) => {
  return (
    <div>
      {
        tab == 1 ?
          <button className={isActive ? "active" : ""} onClick={() => onFuncSelect(func)} value={func}> <img src={icon} style={{ height: "100%" }}></img> {func}</button>
          : tab == 2 ?
            <button>{func}</button>
            : tab == 3 ?
              <button>{func}</button>
              : null
      }
    </div>
  )
}

export default Display
