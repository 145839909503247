import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { getEnvironment, getWorkingEnvironment, setStatement, setNodes } from "../../Actions/Index";
import CustomAlert from "../Utils/CustomAlert"

import SelectTable from "../Operations/SelectTable"
import AddColumn from "../Operations/AddColumn"
import Prefix from "../Operations/Prefix"
import NewColumnName from "../Operations/NewColumnName"
import ExpressionInput from "../Operations/ExpressionInput"
import NumberInput from "../Operations/NumberInput"
import ColumnList from "../Operations/ColumnList"
import UpdateRevewSrcRadioBtn from "../Operations/UpdateRevewSrcRadioBtn"
import NewSourceName from "../Operations/NewSourceName"
import 'bootstrap/dist/css/bootstrap.min.css';


const OperationManager = ({ Operation, selectedStatement, handleClose, Interpret, Run }) => {
    const dispatch = useDispatch();


    const Environment = useSelector((state) => state.getEnvironment);
    const WorkingEnvironment = useSelector((state) => state.getWorkingEnvironment);

    const FileName = useSelector((state) => state.getFileName);
    const Branches = useSelector((state) => state.getBranches);
    const Statements = useSelector((state) => state.setStatement);
    const Nodes = useSelector((state) => state.setNodes);


    const [tables, setTables] = useState([]);
    const [selectedTblCols, setSelectedTblCols] = useState([]);
    const [variables, setVariables] = useState([]);
    const [updateSource, setUpdateSource] = useState(false);
    const [newSrcName, setNewSrcName] = useState("");
    const [output, setOutput] = useState({});
    const [alertData, setAlertData] = useState({
        show: false,
        variant: "success",
        heading: null,
        body: null,
        showCloseBtn: false,
        closeAfter: 0,
        className: "mt-3"
    });

    const hideAlert = () => {
        setAlertData({
            show: false,
            variant: "success",
            heading: null,
            body: null,
            showCloseBtn: false,
            closeAfter: 0,
            className: "mt-3"
        });
    }


    useEffect(() => {
        if (Environment.tables) {

            let _tables = [];
            Environment.tables.forEach(x => {
                _tables.push(x.name)
            })
            setTables(_tables);
        }
        if (Environment.variables) {
            var _variables = [];
            Object.entries(Environment.variables).map(([key, val]) => {
                _variables.push(key)
            })
            setVariables(_variables)
        }
    }, [Environment])


    useEffect(() => {
        if (WorkingEnvironment.tables) {

            let _tables = [];
            WorkingEnvironment.tables.forEach(x => {
                _tables.push(x.name)
            })
            setTables(_tables);
        }
    }, [WorkingEnvironment])

    const updateTableCols = (tableName) => {
        let cols = getTableData(tableName);
        setSelectedTblCols(cols);
    }


    const getTableData = (tableName) => {
        if (Object.keys(WorkingEnvironment).length) {
            let desiredTableData = WorkingEnvironment.tables.find(x => x.name == tableName);
            if (desiredTableData) {
                return desiredTableData.columns;
            }
        }
        else {
            let desiredTableData = Environment.tables.find(x => x.name == tableName);
            if (desiredTableData) {
                return desiredTableData.columns;
            }
        }
        return [];
    }
    const createGuid = () => {
        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }
        return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4()).toLowerCase();
    }

    const updateOutput = (Key, Value) => {
        let obj = {
            ...output
        }
        obj[Key] = Value;
        setOutput(obj);
    }

    const addStatement = () => {
        
        let update_part = "";
        let isSourceNameRequired = Operation.GeneratesResult;


        if (updateSource) {
            if (isSourceNameRequired && output["Source"]) {
                update_part = output["Source"] + " = ";
            }
            else {
                setAlertData({
                    show: true,
                    variant: "danger",
                    heading: null,
                    body: "Source is undefined",
                    showCloseBtn: true,
                    closeAfter: 3000,
                    className: "mt-3"
                });
                return;
            }
        }
        else if (!updateSource && Operation.Name != "Raw") {
            if (newSrcName) {
                update_part = newSrcName + " = ";
            }
            else {
                setAlertData({
                    show: true,
                    variant: "danger",
                    heading: null,
                    body: "Please enter new source name.",
                    showCloseBtn: true,
                    closeAfter: 3000,
                    className: "mt-3"
                });
                return;
            }
        }

        let statementsLength = Statements.length;
        let script = update_part + eval(Operation.GenerateScript)(output);
        
        let _statementID = selectedStatement ? selectedStatement.id : createGuid();
        let selectedBranch = Branches.find(x => x.IsSelected);
        let statement = {
            id: _statementID,
            Name: Operation.Name + "--" + _statementID,
            output: output,
            operation: Operation,
            updateSource: updateSource,
            newSourceName: newSrcName,
            script: script,
            BranchId: selectedBranch ? selectedBranch.ID : 1
        }
        if (selectedStatement) {
            let previousStatement = Statements.find(x => x.id == statement.id);
            let statementIndex = -1;
            if (previousStatement) {
                statementIndex = Statements.indexOf(previousStatement);
                let clonedStatements = [...Statements];
                clonedStatements[statementIndex] = statement;
                let _statements = [...clonedStatements, statement];
                dispatch({ ...setStatement(), statements: _statements })
            }
        }
        else {
            let _statements = [...Statements, statement];

            dispatch({ ...setStatement(), statements: _statements })
            dispatch({ type: "SET_STACK_STAT", stat: _statements })
        }

        //adding into nodes
        var _nodes = [...Nodes];

        let nodesLength = _nodes.length;
        let nodeName = '';
        if (Operation.Name == "Get From Env" || Operation.Name == "Get From Store" || Operation.Name == "Raw") {
            nodeName = Operation.Name
        }
        else if (updateSource) {
            nodeName = `${output.Source} => ${Operation.Name} => ${output.Source}`
        }
        else if (!updateSource && !isNullOrWhitespace(newSrcName)) {
            nodeName = `${output.Source} => ${Operation.Name} => ${newSrcName}`
        }
        let previousNodes = _nodes.filter(x => x.BranchId == selectedBranch.ID);
        if (!selectedStatement) {
            _nodes.push({
                ID: _nodes.length,
                Head_ID: previousNodes[previousNodes.length - 1] ? previousNodes[previousNodes.length - 1].ID : nodesLength - 1,
                Full_Name: nodeName,
                Statement: statement,
                BranchId: selectedBranch.ID,
                type: 'cardWithImageOnLeft',
                Icon: Operation["BlackIcon"],
                Color: selectedBranch ? selectedBranch.Color : "#000",
                ChildBranches: []
            })
            console.log(_nodes)
        }
        else {
            let previousNode = _nodes.find(x => x.Statement && x.Statement.id == selectedStatement.id);
            if (previousNode) {
                previousNode.Full_Name = nodeName;
                previousNode.type = 'cardWithImageOnLeft';
                previousNode.Icon = Operation["BlackIcon"];
                previousNode.Statement = statement;
            }
        }
        dispatch({ ...setNodes(), nodes: _nodes })

        if (!Object.keys(WorkingEnvironment).length) {
            Run(FileName, script)

        } else {

            Interpret(FileName, script)
        }
        handleClose();


    }

    function isNullOrWhitespace(input) {
        return (typeof input === 'undefined' || input == null)
            || input.replace(/\s/g, '').length < 1;
    }
    return (
        <>
            {
                Operation.Params.map((param, index) => {
                    return (
                        <div key={index}>

                            {(param.Type === 'Table') && <SelectTable param={param} tables={tables} updateOutput={updateOutput} setSelectedTblCols={updateTableCols} selectedStatement={selectedStatement} />}
                            {(param.Type === 'Column' && selectedTblCols.length) && <AddColumn param={param} tableCols={selectedTblCols} updateOutput={updateOutput} selectedStatement={selectedStatement} />}
                            {(param.Type === 'Prefix') && <Prefix param={param} updateOutput={updateOutput} selectedStatement={selectedStatement} />}
                            {(param.Type === 'VarName') && <NewColumnName param={param} updateOutput={updateOutput} selectedStatement={selectedStatement} />}
                            {(param.Type === 'Expression') && <ExpressionInput param={param} updateOutput={updateOutput} tableCols={selectedTblCols} variables={variables} prefix={Operation.Params.find(x => x.Type == 'Prefix')} selectedStatement={selectedStatement} />}
                            {(param.Type === 'number') && <NumberInput param={param} updateOutput={updateOutput} selectedStatement={selectedStatement} />}
                            {(param.Type === 'ColumnList') && <ColumnList param={param} tableCols={selectedTblCols} updateOutput={updateOutput} selectedStatement={selectedStatement} />}


                        </div>
                    )
                }
                )
            }
            { Operation.GeneratesResult && <UpdateRevewSrcRadioBtn updateSource={updateSource} setUpdateSource={setUpdateSource} selectedStatement={selectedStatement} />}
            {(!updateSource && Operation.Name != "Raw") && <NewSourceName newSrcName={newSrcName} setNewSrcName={setNewSrcName} selectedStatement={selectedStatement} />}
            <button className='btn btn-primary' onClick={addStatement}> Submit</button>

            <CustomAlert show={alertData.show} hideAlert={hideAlert} variant={alertData.variant} heading={alertData.heading} body={alertData.body} showCloseBtn={alertData.showCloseBtn} className={alertData.className} />
        </>
    );
}

export default OperationManager;