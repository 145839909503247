import React, { useEffect, useState } from 'react';
import { setStatement, setBranches, setNodes, setFileName } from "../../Actions/Index";
import { useSelector, useDispatch } from "react-redux";
import Diagram, {
    Nodes, AutoLayout, Toolbox, PropertiesPanel, ContextMenu, HistoryToolbar
} from 'devextreme-react/diagram';
import ArrayStore from 'devextreme/data/array_store';
import StatementStack from '../../Reducers/StatementsStack';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const DxDiagram = ({ nodesData, setSelectedStatement }) => {


    const [localStatement, setLocalStatement] = useState(null);
    const [selectedNodeData, setSelectedNodeData] = useState(null);
    const [branchNameModel, setBranchNameModel] = useState(false);
    const [branchName, setBranchName] = useState("");
    const dispatch = useDispatch();

    const AllBranches = useSelector((state) => state.getBranches)
    const statements_store = useSelector((state) => state.setStatement)
    const statement_stack = useSelector((state) => state.StatementStack)
    const Node_stack = useSelector((state) => state.setNodes);
    const redo_stack = useSelector((state) => state.RedoStack);
    const FILE = useSelector((state) => state.getFileName);
    const selectionChanged = (e) => {
        if (e.items.length == 1 && e.items[0].dataItem.Statement) {
            setLocalStatement(e.items[0].dataItem.Statement);
        }
        else {
            e.component.setSelectedItems([])
        }
        if (e.items.length == 1 && e.items[0].dataItem) {
            setSelectedNodeData(e.items[0].dataItem)
        }
    }
    function getRandomColor(exception) {
        const colorPalette = ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080']
        let index = Math.floor(Math.random() * (colorPalette.length - 1));
        if (exception == colorPalette[index]) {
            getRandomColor(exception)
        }
        else {
            return colorPalette[index];
        }
    }
    const CreateBranch = () => {
        let selectedNode = nodesData.find(x => x.Statement && x.Statement.id == localStatement.id);
        let _branches = [...AllBranches];
        _branches.forEach(x => x.IsSelected = false);
        let newBranchId = _branches[_branches.length - 1].ID + 1;
        let _color = getRandomColor(selectedNode.Color);
        let newBranch = {
            ID: newBranchId,
            StartNodeId: selectedNode.ID,
            Name: branchName,
            Color: _color,
            IsSelected: true
        };
        _branches.push(newBranch);
        dispatch({ ...setBranches(), branches: _branches })

        let temp_stat = [...statement_stack.statements, {}];
        dispatch({ type: "SET_STACK_STAT", stat: temp_stat })
        dispatch({ ...setStatement(), statements: statement_stack.statements })

        if (selectedNode) {
            var index = nodesData.indexOf(selectedNode);
            nodesData[index].ChildBranches.push(newBranch);
        }
        let _nodes = [...nodesData];
        _nodes.push(
            {
                ID: _nodes.length,
                Head_ID: selectedNode.ID,
                Full_Name: branchName,
                Statement: {},
                BranchId: newBranchId,
                Color: _color,
                IsBranchHead: true,
                type: 'cardWithImageOnLeft',
                Icon: "/OperationIcons/BlackIcons/NewBranch.svg",
                ChildBranches: []
            })
        dispatch({ ...setNodes(), nodes: _nodes })
        handleClose()
    }
    const handleClose = () => {
        setBranchNameModel(false);
        setBranchNameModel("");
    }




    const onCustomCommand = (e) => {
        if (e.name === "UpdateStatement" && localStatement != null) {
            setSelectedStatement(localStatement)
        }
        else if (e.name === "AddBranch" && localStatement != null) {
            setBranchNameModel(true);
        }
        else if (e.name === "SwitchToBranch" && selectedNodeData != null) {
            let _branches = [...AllBranches];
            var branchToBeSelected = _branches.find(x => x.ID == selectedNodeData.BranchId);
            if (branchToBeSelected) {
                _branches.forEach(x => x.IsSelected = false);
                branchToBeSelected.IsSelected = true;
                dispatch({ ...setBranches(), branches: _branches });
            }
        }
        else {
            onCustomHistoryCommand(e);
        }
    }

    const HandleNodesUndo = () => {
        const temp2 = Node_stack.filter(obj => obj.ID != Node_stack[Node_stack.length - 1].ID);

        var redo_node_temp = []

        if (redo_stack.nodes != null) {
            redo_node_temp = [...redo_stack.nodes, Node_stack[Node_stack.length - 1]]
        }
        else {
            redo_node_temp = [Node_stack[Node_stack.length - 1]]
        }

        dispatch({ type: "SET_REDO_STACK_NODE", stat: redo_node_temp }) //REDO STACK
        dispatch({ type: "SET_STACK_NODE", stat: temp2 })
        dispatch({ ...setNodes(), nodes: statement_stack.nodes })

    }
    const HandleStatementsUndo = () => {

        const temp = statement_stack.statements.filter(obj => obj != statement_stack.statements[statement_stack.statements.length - 1])
        var redo_stat_temp = []

        if (redo_stack.statements != null) {
            redo_stat_temp = [...redo_stack.statements, statement_stack.statements[statement_stack.statements.length - 1]]
        }
        else {
            redo_stat_temp = [statement_stack.statements[statement_stack.statements.length - 1]]
        }

        dispatch({ type: "SET_REDO_STACK_STAT", stat: redo_stat_temp }) // REDO STACK

        dispatch({ type: "SET_STACK_STAT", stat: temp })
        dispatch({ ...setStatement(), statements: statement_stack.statements })
    }
    const HandleBranchesUndo = () => {
        const temp_branch = AllBranches.length == 1 ? AllBranches : AllBranches.filter(b => Number(b.ID) != Number(Node_stack[Node_stack.length - 1].BranchId))

        if (!temp_branch.find(x => x.IsSelected)) {
            temp_branch[temp_branch.length - 1].IsSelected = true;
        }
        let temp_b = []
        if (redo_stack.branches != null) {
            temp_b = AllBranches.length == 1 ? AllBranches : [...redo_stack.branches, AllBranches[AllBranches.length - 1]]
        }
        else {
            temp_b = AllBranches //[Node_stack[Node_stack.length - 1]]
        }

        if (temp_branch.filter(x => x.IsSelected).length > 1) {
            temp_branch.forEach(x => {
                x.IsSelected = false;
            })
            temp_branch[temp_branch.length - 1].IsSelected = true;
        }
        dispatch({ type: "SET_STACK_BRANCH", stat: temp_branch });
        dispatch({ type: "SET_REDO_STACK_BRANCH", stat: temp_b });
        dispatch({ ...setBranches(), branches: temp_branch });

    }
    const HandleFileNameUndo = () => {

        let prev_Name = statement_stack.File[statement_stack.File.length - 1];
        let FileNames = [...redo_stack.File, prev_Name];
        dispatch({ type: "SET_REDO_STACK_FILENAME", stat: FileNames });

        const temp_file = statement_stack.File.filter(obj => obj != statement_stack.File[statement_stack.File.length - 1])
        dispatch({ type: "SET_STACK_FILENAME", stat: temp_file })

        dispatch({ ...setFileName(), fileName: statement_stack.File[statement_stack.File.length - 1] })

    }
    const HandleFileNameRedo = () => {
        dispatch({ ...setFileName(), fileName: redo_stack.File[redo_stack.File.length - 1] })

        let prev_Name = redo_stack.File[redo_stack.File.length - 1];
        let FileNames = [...statement_stack.File, prev_Name];
        dispatch({ type: "SET_STACK_FILENAME", stat: FileNames })

        const temp_file = redo_stack.File.filter(obj => obj != redo_stack.File[redo_stack.File.length - 1])
        dispatch({ type: "SET_REDO_STACK_FILENAME", stat: temp_file })

    }
    const HandleBranchesRedo = () => {
        let temp5 = [];
        if (statement_stack.branches != null) {
            if (statement_stack.branches.length == 1 && redo_stack.branches.length == 1 && statement_stack.branches[0].ID == redo_stack.branches[0].ID) {
                temp5 = statement_stack.branches
            }
            else {
                temp5 = [...statement_stack.branches, redo_stack.branches[redo_stack.branches.length - 1]]
            }
        }
        else {
            temp5 = [redo_stack.branches[redo_stack.branches.length - 1]]
        }

        if (statement_stack.branches.filter(x => x.IsSelected).length > 1) {
            statement_stack.branches.forEach(x => {
                x.IsSelected = false;
            })
            statement_stack.branches[statement_stack.branches.length - 1].IsSelected = true;
        }
        if (statement_stack.branches.filter(x => x.IsSelected).length == 0) {
            statement_stack.branches[statement_stack.branches.length - 1].IsSelected = true;
        }

        dispatch({ type: "SET_STACK_BRANCH", stat: temp5 })

        const temp6 = redo_stack.branches.filter(obj => obj != redo_stack.branches[redo_stack.branches.length - 1])
        dispatch({ type: "SET_REDO_STACK_BRANCH", stat: temp6 }) // REDO STACK
        dispatch({ ...setBranches(), branches: statement_stack.branches })


    }
    const HandleNodesRedo = () => {
        const temp3 = [...statement_stack.nodes, redo_stack.nodes[redo_stack.nodes.length - 1]]
        dispatch({ type: "SET_STACK_NODE", stat: temp3 })

        const temp4 = redo_stack.nodes.filter(obj => obj != redo_stack.nodes[redo_stack.nodes.length - 1])
        dispatch({ type: "SET_REDO_STACK_NODE", stat: temp4 }) // REDO STACK
        dispatch({ ...setNodes(), nodes: statement_stack.nodes })
    }
    const HandleStatementsRedo = () => {
        const temp = [...statement_stack.statements, redo_stack.statements[redo_stack.statements.length - 1]]
        dispatch({ type: "SET_STACK_STAT", stat: temp })

        const temp2 = redo_stack.statements.filter(obj => obj != redo_stack.statements[redo_stack.statements.length - 1])
        dispatch({ type: "SET_REDO_STACK_STAT", stat: temp2 }) // REDO STACK
        dispatch({ ...setStatement(), statements: statement_stack.statements })
    }
    const onCustomHistoryCommand = (e) => {
        if (e.name === "undo2") {

            if (statements_store.length == 0) { return; }
            HandleBranchesUndo();
            HandleStatementsUndo();
            HandleNodesUndo();
            HandleFileNameUndo();


        }
        else if (e.name === "redo2") {
            if (redo_stack.nodes.length == 0) { return; }
            // if (Node_stack.length == 1) { return; }
            //Statemetns
            HandleStatementsRedo();
            //Nodes
            HandleNodesRedo();

            //Branches
            HandleBranchesRedo();
            HandleFileNameRedo();
        }
    }
    const menuCommands = [{
        name: "UpdateStatement",
        text: "Update Statement",
        icon: "edit",
    }, {
        name: "AddBranch",
        text: "Add Branch",
        icon: "add",
    }, {
        name: "SwitchToBranch",
        text: "Switch to Branch",
        icon: "sorted",
    }];
    const HistoryCommands = [{ name: "undo2", text: "undo", icon: "undo" }, { name: "redo2", text: "redo", icon: "redo" }];
    const dataSource = new ArrayStore({
        key: 'ID',
        data: nodesData,
    });
    const onRequestEditOperation = (e) => {
        if (e.operation === "addShape" || e.operation === "addConnector" || e.operation === "drag" || e.operation === "changeConnectorPoints" || e.operation === "addShapeFromToolbox") {
            e.allowed = false; // prevent new node and arrow creation and dragging edges
        }
    };

    return (
        <>

            <Diagram id="diagram" className='height-100' simpleView={true} onCustomCommand={onCustomCommand} onSelectionChanged={selectionChanged} onRequestEditOperation={onRequestEditOperation}>
                <Nodes dataSource={dataSource} keyExpr="ID" textExpr="Full_Name" parentKeyExpr="Head_ID" imageUrlExpr="Icon">
                    <AutoLayout type="tree" />
                </Nodes>
                <Toolbox visibility="disabled" />
                <PropertiesPanel visibility="disabled" />
                <ContextMenu enabled={true} commands={menuCommands} />
                <HistoryToolbar visible={true} commands={HistoryCommands} />
            </Diagram>
            <Modal show={branchNameModel} onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>New Branch Name</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label htmlFor="BranchName" className="form-label">Please enter branch name:</label>
                    <input id="BranchName" value={branchName} onChange={e => setBranchName(e.target.value)}
                        type="text" className="form-control mb-3" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={CreateBranch}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}


export default DxDiagram;
