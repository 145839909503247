import React, { useEffect, useState } from 'react';
import SimpleDropdown from '../DevextremeComponents/DevExtremeSelectBox';


const AddColumn = ({ param, tableCols, updateOutput, selectedStatement }) => {
    const [initValue, setInitValue] = useState(null);
    // useEffect(() => {
    //     console.log("dataSource: ", dataSource)
    // }, [dataSource])
    useEffect(() => {
        if (selectedStatement && selectedStatement.output[param?.Name]) {
            setInitValue(tableCols.find(x => x === selectedStatement.output[param?.Name]));
            updateOutput(param.Name, tableCols.find(x => x === selectedStatement.output[param?.Name]));
        }
        else if (tableCols.length) {
            setInitValue(tableCols[0]);
            updateOutput(param.Name, tableCols[0]);
        }
    }, [tableCols])


    const setData = (value) => {
        updateOutput(param.Name, value);        
        setInitValue(value);
    }

    return (
        <div id="colname-Add" className="form-group" style={{ "display": param.Visible ? "" : "none" }}>
            <label htmlFor="newColName" className="form-label">{param.Name}:</label>
            {/* <select onChange={e => updateOutput(param.Name, e.target.value)} className="custom-select form-select mb-3"
                id="ColName-Add" title={param.Description} >
                {tableCols.map((colName, i) => (
                    <>
                        {(initValue === colName) && <option key={i} value={colName} selected> {colName} </option>}
                        {(initValue !== colName) && <option key={i} value={colName}> {colName} </option>}
                    </>
                ))}
            </select> */}
            <SimpleDropdown dataSource={tableCols} selectedItem={initValue} setData={setData}></SimpleDropdown>
        </div>
    );
}

export default AddColumn;