const Config = {};

const getConfig = async (state = Config, action) => {
    if (action.type === "GET_CONFIG") {
        const res = await fetch('/config.json');
        state =  await res.json();
    }    
    return state;
}

export default getConfig;