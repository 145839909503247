const Nodes = [
    {
        ID: 0,
        Full_Name: 'Start',
        Head_ID: null,
        type: 'cardWithImageOnLeft',
        Icon:  "/OperationIcons/BlackIcons/MainBranch.svg", 
        ChildBranches: []
    }];
const setNodes = (state = Nodes, action) => {
    if (action.type === "SET_NODES") {
        state = action.nodes
    }
    return state;
}

export default setNodes;