import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setGraphObjects } from "../../Actions/Index"
import AppStateContext from '../../ContextApi/AppState';


import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import CollectionWidget from "devextreme/ui/collection/ui.collection_widget.base";

var isFirstTime = true;
const Visualizer = ({ visualizerInitialized, setVisualizerInitialized }) => {
    const { toggleReview, graphObjectIndex } = React.useContext(AppStateContext);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const FileName = useSelector((state) => state.getFileName);
    const GraphObjects = useSelector((state) => state.setGraphData);
    const dispatch = useDispatch();

    useEffect(() => {
        if (FileName.length) {
            setVisualizerInitialized(true);
            window.setFormWizard();
            window.InitiateTab1(FileName, graphObjectIndex != -1 ? GraphObjects[graphObjectIndex]?.result : null);
            window.bindModalHideEvent();
        }
        window.chartSelectionInit();
    }, [FileName])


    const VisualizationvalueChanged = (value) => {
        window.VisualizationvalueChanged(value);
    }
    const ColorValueChanged = () => {
        window.ColorValueChanged();
    }
    const validateSeriesName = () => {
        window.validateSeriesName();
    }
    const ValidateTitle = () => {
        window.ValidateTitle();
    }
    const ValidatexAxisTitle = () => {
        window.ValidatexAxisTitle();
    }
    const validateYAxisTitle = () => {
        window.validateYAxisTitle();
    }
    const GenerateSeries = () => {
        window.GenerateSeries();
    }
    const InititateSaveDataModal = () => {
        window.InititateSaveDataModal();
    }
    const InitiateStrip = () => {
        window.InitiateStrip();
    }
    const ShowConstantLineModal = () => {
        window.ShowConstantLineModal();
    }
    const closeLeftMenu = () => {
        setSidebarVisible(false);
        window.closeLeftMenu();
    }
    const TabChanged = (tabset) => {
        window.TabChanged(tabset);
    }
    const onTabConfirmSelection = () => {
        window.onTabConfirmSelection();
    }
    const openLeftMenu = () => {
        setSidebarVisible(true);
        window.openLeftMenu();
    }
    const AddNewSeries = () => {
        window.AddNewSeries();
    }
    const AddNewConsantLine = () => {
        window.AddNewConsantLine();
    }
    const AddStrips = () => {
        window.AddStrips();
    }
    const onInitiateEditSeries = () => {
        window.onInitiateEditSeries();
    }
    const onInitiateRemoveSeries = () => {
        window.onInitiateRemoveSeries();
        window.closeBtn_removeSeries();
    }
    const SaveData = async () => {
        var data = await window.SaveData();
        dispatch({ ...setGraphObjects(), graphData: [...GraphObjects, data] });
        toggleReview();
    }
    const closeSeriesModel = () => {
        window.closeSeriesModel();
    }
    const HideConstantLineModel = () => {
        window.HideConstantLineModel();
    }
    const hideStripesModel = () => {
        window.hideStripesModel();
    }
    const hideSaveDataModel = () => {
        window.hideSaveDataModel();
    }

    const backToReview = () => {
        window.toggleReviewApps(toggleReview)
    }
    return (
        <>
            <nav class="navbar navbar-expand-lg  navbar-dark bg-primary">

                <div className="collapse navbar-collapse">
                    <a class="navbar-brand" style={{ marginLeft: "20px" }} href="javascript:void(0)" onClick={backToReview}>
                        <span class="material-icons" style={{ position: "absolute", top: "14px" }}>
                            arrow_back_ios
                        </span>
                        <span style={{ marginLeft: "35px" }}>Back</span></a>
                </div>

            </nav>
            <div className="main" id="Main_Container" style={{ display: "block" }}>
                <div className="container_form" >
                    <form method="POST" id="signup-form" className="signup-form" action="#"  >
                        <div>
                            <h3>Chart Type</h3>
                            <fieldset>
                                <h2>Select Chart Type</h2>
                                <p className="desc">Please select a chart type to proceed</p>
                                <div className="fieldset-content" >
                                    <div className="choose-bank">
                                        <div className="form-radio-flex">
                                            <div className="form-radio-item" onClick={() => VisualizationvalueChanged("BarChart")}>
                                                <input type="radio" name="choose_bank" id="bank_1" value="BarChart" defaultChecked />
                                                <label htmlFor="bank_1"><img src="./assets/FormWizard/images/barchart.PNG" alt="" />Bar Chart</label>
                                            </div>

                                            <div className="form-radio-item" onClick={() => VisualizationvalueChanged("LineChart")}>
                                                <input type="radio" name="choose_bank" id="bank_2" value="LineChart" />
                                                <label htmlFor="bank_2"><img src="./assets/FormWizard/images/linechart.PNG" alt="" />Line Chart</label>
                                            </div>

                                            <div className="form-radio-item" onClick={() => VisualizationvalueChanged("PointChart")}>
                                                <input type="radio" name="choose_bank" id="bank_3" value="PointChart" />
                                                <label htmlFor="bank_3"><img src="./assets/FormWizard/images/pointchart.PNG" alt="" />Point Chart</label>
                                            </div>

                                            <div className="form-radio-item" onClick={() => VisualizationvalueChanged("PieChart")}>
                                                <input type="radio" name="choose_bank" id="bank_4" value="PieChart" />
                                                <label htmlFor="bank_4"><img src="./assets/FormWizard/images/piechart.PNG" alt="" />Pie Chart</label>
                                            </div>

                                            <div className="form-radio-item" onClick={() => VisualizationvalueChanged("SeriesBar")}>
                                                <input type="radio" name="choose_bank" id="bank_5" value="SeriesBar" />
                                                <label htmlFor="bank_5"><img src="./assets/FormWizard/images/seriesbar.png" alt="" />Series Bar Chart</label>
                                            </div>
                                        </div>

                                        <div id="colorValueDiv" style={{ display: "none" }}>
                                            <label htmlFor="email" className="form-label">Select Graph Color</label>
                                            <input type="color" id="colorValue" onChange={() => ColorValueChanged()} value="#4966b1" />
                                        </div>
                                    </div>
                                    {/* 
                                    <div id="colorValueDiv" style={{ display: "none" }}>
                                        <label htmlFor="email" className="form-label">Select Graph Color</label>
                                        <input type="color" id="colorValue" onChange={() => ColorValueChanged()} value="#4966b1" />
                                    </div> */}

                                    <div id="SeriesChartType" style={{ display: "none" }}>
                                        <label htmlFor="email" className="form-label">Select Series Chart Type</label>
                                        <div id="SeriesOptions"></div>
                                    </div>
                                </div>
                            </fieldset>
                            <h3>Visualization Data</h3>
                            <fieldset>
                                <h2>Visualization Data</h2>
                                <p className="desc">Please enter your infomation and proceed to next step so we can build your account</p>
                                <div className="fieldset-content">
                                    <div className="form-group">
                                        <div id="env_table" ></div>
                                    </div>
                                    <div className="form-group" style={{ display: "none" }} id="group_key_input">
                                        <div id="GroupKey"></div>
                                    </div>

                                    <div className="form-group" style={{ display: "none" }} id="secondary_group_key_input">
                                        <div id="secondaryGroupKey"></div>
                                    </div>

                                    <div className="form-group" id="x_axis_input" style={{ display: "none" }}>
                                        <div id="x-axis" ></div>
                                    </div>

                                    <div className="form-group" style={{ display: "none" }} id="y_axis_input">
                                        <div id="y-axis" ></div>
                                    </div>

                                    <div className="form-group" style={{ display: "none" }} id="high_error_input">
                                        <div id="highEndCol" ></div>
                                    </div>

                                    <div className="form-group" style={{ display: "none" }} id="low_error_input">
                                        <div id="lowEndCol" ></div>
                                    </div>

                                </div>
                            </fieldset>

                            <h3>Visualization Titles</h3>
                            <fieldset>
                                <h2>Set Visualization Titles</h2>
                                <p className="desc">Set up your Visualization Titles for better readabilty</p>

                                <div className="form-group" id="seriesNameForm" style={{ display: "block" }}>
                                    <label htmlFor="email" className="form-label">Series Name</label>
                                    <input type="text" id="series_name" placeholder="Enter Series Name" onKeyUp={validateSeriesName} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email" className="form-label">Visualization Title</label>
                                    <input type="text" id="title" placeholder="Enter any expression within { }" onKeyUp={ValidateTitle} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email" className="form-label">X Axis Title</label>
                                    <input type="text" placeholder="Enter any expression within { }" id="x_axisTitle" onKeyUp={ValidatexAxisTitle} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email" className="form-label">Y Axis Title</label>
                                    <input type="text" placeholder="Enter any expression within { }" id="y_axisTitle" onKeyUp={validateYAxisTitle} />
                                </div>
                            </fieldset>

                        </div>
                    </form>
                </div>
            </div>






            <div id="series">
                <nav class="navbar navbar-expand-lg  navbar-light bg-default sub-nav">
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse">

                        {!sidebarVisible && <div className="sidebar-opener-parent" style={{ width: "60px" }} onClick={openLeftMenu}>
                            <span class="material-icons sidebar-opener-child">
                                menu_open
                            </span>
                        </div>}
                        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                            <li class="nav-item">
                                <a href="javascript:void(0)" id="addSeriesBtn" className="nav-link sub-nav-items" data-toggle="modal"
                                    data-target="#AddSeriesModal" onClick={GenerateSeries} style={{ display: "none" }}>Add New Series</a>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" id="addconstanLineBtn" className="nav-link sub-nav-items" data-toggle="modal"
                                    data-target="#AddConstantLinesModal" onClick={ShowConstantLineModal} style={{ display: "none" }}>Add New Reference Line</a>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" id="addstripBtn" className="nav-link sub-nav-items" data-toggle="modal"
                                    data-target="#AddstripsModal" onClick={InitiateStrip} style={{ display: "none" }}>Add New Strip</a>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" id="savedataBtn" className="nav-link sub-nav-items" data-toggle="modal"
                                    data-target="#SaveDataModal" style={{ display: "none" }} onClick={InititateSaveDataModal}>Save Data</a>
                            </li>
                        </ul>
                    </div>

                </nav>
            </div>

            <div id="sidebar" style={{ display: "none" }}>

                <div className="w3-sidebar w3-bar-block w3-card w3-animate-left" style={{ display: "none", top: "50px" }} id="leftMenu">
                    <nav class="nav navbar navbar-expand-lg  navbar-light bg-default sub-header">
                        <div className="container-fluid">
                            <span>Edit / Remove</span>
                        </div>
                        <div onClick={closeLeftMenu} className="sidebar-opener-parent">
                            <span class="material-icons sidebar-opener-child">
                                <span class="material-symbols-outlined">
                                    chevron_left
                                </span>
                            </span>
                        </div>
                    </nav>
                    <div style={{ marginTop: "51px !important" }}>
                        <nav>
                            <div className="nav nav-tabs navbar navbar-expand-lg  navbar-light bg-default" id="nav-tab" role="tablist">
                                <button onClick={() => TabChanged('chart')} className="nav-link active" id="nav-chart-tab" data-bs-toggle="tab" data-bs-target="#nav-chart" type="button" role="tab" aria-controls="nav-chart" aria-selected="true">Chart</button>
                                <button onClick={() => TabChanged('strip')} className="nav-link" id="nav-strip-tab" data-bs-toggle="tab" data-bs-target="#nav-strip" type="button" role="tab" aria-controls="nav-strip" aria-selected="false">Strip</button>
                                <button onClick={() => TabChanged('line')} className="nav-link" id="nav-ref-line-tab" data-bs-toggle="tab" data-bs-target="#nav-ref-line" type="button" role="tab" aria-controls="nav-ref-line" aria-selected="false">Refrence Line</button>
                            </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-chart" role="tabpanel" aria-labelledby="nav-chart-tab">
                                <div className="container-fluid">
                                    <h3 className="tab-heading">Select Chart To Edit</h3>
                                    <div id="EditableSeries"></div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-strip" role="tabpanel" aria-labelledby="nav-strip-tab">
                                <div className="container-fluid">
                                    <h3 className="tab-heading">Select Strip To Edit</h3>
                                    <div id="EditableStrips"></div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-ref-line" role="tabpanel" aria-labelledby="nav-ref-line-tab">
                                <div className="container-fluid">
                                    <h3 className="tab-heading">Select Refrence Line To Edit</h3>
                                    <div id="EditableLine"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="tabset">

                        <input type="radio" name="tabset" id="tab1" value="chart" aria-controls="marzen" defaultChecked onClick={TabChanged} />
                        <label htmlFor="tab1">Chart</label>

                        <input type="radio" name="tabset" id="tab2" value="strip" aria-controls="rauchbier" onClick={TabChanged} />
                        <label htmlFor="tab2">Strip</label>

                        <input type="radio" name="tabset" id="tab3" value="line" aria-controls="dunkles" onClick={TabChanged} />
                        <label htmlFor="tab3">Refrence Line</label>

                        <div className="tab-panels">
                            <section id="ChartTab" className="tab-panel container-fluid">
                                <h3>Select Chart To Edit</h3>
                                <div id="EditableSeries"></div>


                            </section>

                            <section id="StripTab" className="tab-panel container-fluid">
                                <h3>Select Strip To Edit</h3>
                                <div id="EditableStrips"></div>
                            </section>
                            <section id="ReferenceLineTab" className="tab-panel container-fluid">
                                <h3>Select Refrence Line To Edit</h3>
                                <div id="EditableLine"></div>
                            </section>
                        </div>

                    </div> */}

                    <div className="row container-fluid">
                        <div className="col-6">
                            <button id="editseries" className="btn btn-primary btn-sm float-right mr-lg-2" style={{ display: "none", height: "50px" }}
                                data-toggle="modal" data-target="#EditSeriesModal" onClick={onTabConfirmSelection}>Edit</button>
                        </div>
                        <div className="col-6">
                            <button id="removeseries" className="btn btn-primary btn-sm float-right mr-lg-2" style={{ display: "none", height: "50px" }}
                                data-toggle="modal" data-target="#RemoveSeriesModal" >Remove</button>
                        </div>
                    </div>


                </div>

                {/* <div className="w3-teal">
                    <button className="w3-button w3-teal w3-xlarge w3-left" style={{ width: "60px" }} onClick={openLeftMenu}>&#9776;</button>
                </div> */}
            </div>

            <div className="Chart" id="ChartDisplay" style={{ display: "none", backgroundColor: "white" }}>
            </div>

            <div className="modal fade" id="AddSeriesModal" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="AddSeriesModalTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Add Series In Existing Chart</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" id="AddSeriesmodalBody">
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" disabled id="AddNewSeriesBtn" onClick={AddNewSeries}>Add New Series</button>
                            <button type="button" className="btn btn-danger" id="closeBtn_NewSeries" onClick={closeSeriesModel} data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="AddConstantLinesModal" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog"
                aria-labelledby="AddConstantLinesModalTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Add Constant Lines</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" id="AddConstantLines">
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={AddNewConsantLine}>Add New
                                Constant Line</button>
                            <button type="button" className="btn btn-danger" id="closeBtn_NewSeries" onClick={HideConstantLineModel} data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="AddstripsModal" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Add Strips</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" id="Addstrips">
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={AddStrips}>Add New Strip</button>
                            <button type="button" className="btn btn-danger" id="closeBtn_NewSeries" onClick={hideStripesModel} data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="EditSeriesModal" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Make Changes</h5>
                            <button type="button" className="close" onClick={window.closeBtn_EditSeries} data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" id="EditseriesHtml">
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" style={{ width: "100% !important" }} id="edit_confirm_btn" onClick={onInitiateEditSeries}>Confirm Save Changes </button>
                            <button type="button" className="btn btn-danger" onClick={window.closeBtn_EditSeries} id="closeBtn_EditSeries" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="RemoveSeriesModal" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Remove Selection</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div>
                            <div className="form-group">
                                <h5>Are you sure you want to delete?</h5>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" id="remove_confirm_btn" onClick={onInitiateRemoveSeries}>Confirm Remove </button>
                            <button type="button" className="btn btn-danger" onClick={window.closeBtn_removeSeries} id="closeBtn_removeSeries" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="SaveDataModal" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="SaveDataModalTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="SaveDataModalLongTitle">Save Data</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" id="SaveDataModalBody">

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" id="SaveDataModalBtn" onClick={SaveData}>Save Data</button>
                            <button type="button" className="btn btn-danger" id="closeBtn_NewSeries" onClick={hideSaveDataModel} data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Visualizer;