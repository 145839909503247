import React, { useEffect, useState } from 'react';
import 'devextreme/dist/css/dx.light.css';
import { getEnvironment, getWorkingEnvironment, setGraphObjects } from "../../Actions/Index"
import 'devextreme/data/odata/store';
import Operations from '../../Data/Operations.json';
import "../../Static/Enviornment.css";
import Display from './Display';
import { useSelector, useDispatch } from "react-redux";
import Grid from "../DevextremeComponents/DevExtremeGrid"
import AppStateContext from '../../ContextApi/AppState';
import { getRequest, postRequest } from "../../Services/HttpService"

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Swal from 'sweetalert2'

const Enviornment = ({ onSelectedOperation, onTableClicked }) => {
    const { toggleReview } = React.useContext(AppStateContext);


    const GraphObjects = useSelector((state) => state.setGraphData);
    const dispatch = useDispatch();
    const Env = useSelector((state) => state.getEnvironment);
    const FileName = useSelector((state) => state.getFileName);
    const WorkingEnv = useSelector((state) => state.getWorkingEnvironment);
    const [tab, setTab] = useState(1);
    const GetFunctions = () => {
        const func_list = [];
        Operations.forEach(obj => {
            func_list.push({
                name: obj["Name"],
                icon: obj["WhiteIcon"]
            });
        });

        return func_list;
    }
    const ToDataVisualizer = () => {
        window.ToggleLoader()
        toggleReview();
    }

    const EditGraph = (index) => {

    }
    const PreviewGraph = (index) => {
        window.ToggleLoader()
        var clonedGraphObjects = GraphObjects[index];
        toggleReview(index)

        //createVisualization(clonedGraphObjects.result)
    }
    const CloneGraph = (index) => {
        var clonedGraphObjects = [...GraphObjects, GraphObjects[index]];
        dispatch({ ...setGraphObjects(), graphData: clonedGraphObjects });
    }
    const DeleteGraph = (index) => {
        if (window.confirm("Do you really want to delete this ?") == true) {
            var clonedGraphObjects = [...GraphObjects];
            clonedGraphObjects.splice(index, 1);
            dispatch({ ...setGraphObjects(), graphData: clonedGraphObjects });
        }
    }

    const GetVariables = () => {
        if (!Object.keys(Env).length)
            return []
        const var_list = [];
        const Vars = Env.variables

        Object.entries(Vars).map(([key, val]) => {
            var_list.push({ "Property": key, "Value": val });
        })
        return var_list;
    }
    const GetWorkingEnvVariables = () => {
        if (!Object.keys(WorkingEnv).length)
            return []
        const var_list = [];
        const Vars = WorkingEnv.variables

        Object.entries(Vars).map(([key, val]) => {
            var_list.push({ "Property": key, "Value": val });
        })
        return var_list;
    }
    const GetTables = () => {
        if (!Object.keys(Env).length)
            return []
        const tables_list = [];
        const tables = Env.tables;
        tables.forEach(x => {
            tables_list.push(x.name)
        })
        return tables_list;
    }
    const GetCurrentEnvTables = () => {
        if (!Object.keys(WorkingEnv).length)
            return []
        const tables_list = [];
        const tables = WorkingEnv.tables;
        tables.forEach(x => {
            tables_list.push(x.name)
        })
        return tables_list;
    }
    const onSelection = (func) => {

        onSelectedOperation(func)

    }

    const AddNewVariableInStartEnv = () => {
        // Swal.fire({
        //     title: 'Enter Variable Key and Value',
        //     html:
        //         '<label for="swal-keyInput">Key</label>' +
        //         '<input id="swal-keyInput" style = "width: 100%;margin-left: 0px;" class="swal2-input" placeholder="Enter Key Here">' +
        //         '<label for="swal-valueInput">Value</label>' +
        //         '<input id="swal-valueInput" style = "width: 100%;margin-left: 0px;" class="swal2-input" placeholder="Enter Value Here">',
        //     showCancelButton: true,
        //     confirmButtonText: 'Submit',
        //     cancelButtonText: 'Cancel',
        //     preConfirm: () => {
        //         const propKey = document.getElementById('swal-input1').value
        //         const propVal = document.getElementById('swal-input2').value
        //         return { propKey: propKey, propVal: propVal }
        //     }
        // }).then((result) => {
        //     if (result.isConfirmed) {
        //         const propKey = result.value.propKey
        //         const propVal = result.value.propVal
        //         console.log(`Name: ${name}, Email: ${email}`)

        //         let storeData = JSON.stringify(AllStoreData);

        //     }
        // })
        Swal.fire({
            title: 'Enter the expression to add variable',
            input: 'text',
            inputLabel: 'Expression',
            inputPlaceholder: 'variable_name = [expression_for_value]',
            showCancelButton: true,
            confirmButtonText: 'Submit',
        }).then((result) => {
            if (result.value) {
                var queryStrings = (function (a) {
                    if (a == "") return {};
                    var b = {};
                    for (var i = 0; i < a.length; ++i) {
                        var p = a[i].split('=', 2);
                        if (p.length == 1)
                            b[p[0]] = "";
                        else
                            b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
                    }
                    return b;
                })(window.location.search.substr(1).split('&'));

                let fileKey;
                if (queryStrings?.fileKey) {
                    fileKey = queryStrings?.fileKey;
                }
                var exp = result.value;
                postRequest(`/DataReview/AddParameter?link=${fileKey}`, exp).then((result) => {
                    console.log("template result:", result);
                    getRequest("/DataReview/Describe?link=" + fileKey).then((result) => {
                        dispatch({ ...getEnvironment(), envData: result.data });
                    })
                    Swal.fire(
                        'Saved!',
                        'Template have been saved successfully.',
                        'success'
                    )
                }).catch(err => {
                    console.log(err)
                })
            }
        });
    }

    const onToggle = (sel) => {
        setTab(sel);
    }
    const createGuid = () => {
        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }
        return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4()).toLowerCase();
    }
    return (


        <div>
            <Tabs
                defaultActiveKey="StartingEnvironment"
                id="fill-tab-example1"
                className="mb-3"
                fill
            >
                <Tab eventKey="StartingEnvironment" title="Starting Environment">
                    <Tabs
                        defaultActiveKey="Functions"
                        id="fill-tab-example2"
                        className="mb-3"
                        fill
                    >
                        <Tab eventKey="Functions" title="Functions">
                            {
                                GetFunctions().map((func, i) => (
                                    <div key={i}>
                                        <Display func={func.name} onFuncSelect={onSelection} tab={tab} icon={func.icon} />
                                    </div>

                                ))
                            }
                        </Tab>
                        <Tab eventKey="Tables" title="Tables">
                            {
                                GetTables().map((obj, i) => (
                                    <div key={i}>
                                        <Display func={obj} onFuncSelect={onTableClicked} tab={tab} />
                                    </div>
                                ))
                            }
                        </Tab>
                        <Tab eventKey="Variables" title="Variables">
                            <button className='btn btn-success' onClick={AddNewVariableInStartEnv}>Add Veriable</button>
                            <Grid key={"Property"} dataSource={GetVariables()} />
                        </Tab>

                        <Tab eventKey="CreateVisualization" title="Create Visualization">
                            <button onClick={ToDataVisualizer}>Create Visualization</button>
                            {

                                GraphObjects.map((object, index) => (
                                    <>
                                        <Container className='custom-container' key={index}>
                                            <Row>
                                                <Col style={{ margin: "10px" }}>
                                                    <h4>{object.profileName}</h4>
                                                </Col>
                                                <Col style={{ marginTop: "3px" }}>

                                                    <div className='row'>
                                                        <div className='col-4'>
                                                            <button className='btn btn-danger' style={{ width: "90%", padding: "8px" }} onClick={() => DeleteGraph(index)}>
                                                                <span class="material-icons">
                                                                    delete
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div className='col-4'>
                                                            <button className='btn btn-secondary' style={{ width: "90%", padding: "8px" }} onClick={() => CloneGraph(index)}>
                                                                <span class="material-icons">
                                                                    content_copy
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div className='col-4'>
                                                            <button className='btn btn-success' style={{ width: "90%", padding: "8px" }} onClick={() => PreviewGraph(index)}>
                                                                <span class="material-icons">
                                                                    preview
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </>
                                ))
                            }
                        </Tab>
                    </Tabs>
                </Tab>
                <Tab eventKey="CurrentEnvironment" title="Current Environment">
                    <Tabs
                        defaultActiveKey="Functions"
                        id="fill-tab-example3"
                        className="mb-3"
                        fill
                    >
                        <Tab eventKey="Functions" title="Functions">
                            {
                                GetFunctions().map((func, i) => (
                                    <div key={i}>
                                        <Display func={func.name} onFuncSelect={onSelection} tab={tab} icon={func.icon} />
                                    </div>

                                ))
                            }
                        </Tab>
                        <Tab eventKey="Tables" title="Tables">
                            {
                                GetCurrentEnvTables().map((func, i) => (
                                    <div key={i}>
                                        <Display func={func} onFuncSelect={onTableClicked} tab={tab} />
                                    </div>

                                ))
                            }
                        </Tab>
                        <Tab eventKey="Variables" title="Variables">
                            <Grid key={"Property"} dataSource={GetWorkingEnvVariables()} />
                        </Tab>

                        <Tab eventKey="CreateVisualization" title="Create Visualization">
                            <button onClick={ToDataVisualizer} >Create Visualization</button>
                            {
                                GraphObjects.map((object, index) => (
                                    <>

                                        <Container className='custom-container' key={index}>
                                            <Row>
                                                <Col style={{ margin: "10px" }}>
                                                    <h4>{object.profileName}</h4>
                                                </Col>
                                                <Col style={{ marginTop: "3px" }}>

                                                    <div className='row'>
                                                        <div className='col-4'>
                                                            <button className='btn btn-danger' style={{ width: "90%", padding: "8px" }} onClick={() => DeleteGraph(index)}>
                                                                <span class="material-icons">
                                                                    delete
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div className='col-4'>
                                                            <button className='btn btn-secondary' style={{ width: "90%", padding: "8px" }} onClick={() => CloneGraph(index)}>
                                                                <span class="material-icons">
                                                                    content_copy
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div className='col-4'>
                                                            <button className='btn btn-success' style={{ width: "90%", padding: "8px" }} onClick={() => PreviewGraph(index)}>
                                                                <span class="material-icons">
                                                                    preview
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </>
                                ))
                            }
                        </Tab>
                    </Tabs>
                </Tab>
            </Tabs>
        </div>
    );
}
export default Enviornment;