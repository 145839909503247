const stack={
    statments:[],
    nodes:[],
    branches:[],
    File:[]
}

const RedoStack = (state = stack, action) => {
    if(action.type === "SET_REDO_STACK_STAT"){
        state.statements = action.stat;
    }
    else if(action.type === "SET_REDO_STACK_NODE")
    {
        state.nodes = action.stat;
    }
    else if(action.type === "SET_REDO_STACK_BRANCH")
    {
        state.branches = action.stat;
    }
    else if(action.type === "SET_REDO_STACK_FILENAME")
    {
        state.File = action.stat;
    }
    return state;
}

export default RedoStack;