const Branches = [{
    ID: 1,
    StartNodeId: 1,
    Name: "Start",
    Color: "#000",
    IsSelected : true
  }];
const getBranches = (state = Branches, action) => {
    if(action.type === "SET_BRANCHES"){
        state =  action.branches
    }
    return state;
}

export default getBranches;