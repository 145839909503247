import AppStateContext from './AppState';
import React from 'react';

class AppStateProvider extends React.Component {
    state = {
        showReview: true,
        graphObjectIndex: -1
    };

    render() {
        return (
            <AppStateContext.Provider
                value={{
                    showReview: this.state.showReview,
                    graphObjectIndex : this.state.graphObjectIndex,
                    toggleReview: (selectedID) => {
                        var newVal = !this.state.showReview
                        this.setState({
                            showReview: newVal,
                            graphObjectIndex: selectedID != undefined && selectedID > -1 ? selectedID : -1
                        });
                    }
                }}
            >
                {this.props.children}
            </AppStateContext.Provider>
        );
    }
}

export default AppStateProvider;