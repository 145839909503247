import axios from "axios";


const getConfig = async () => {
    const res = await fetch('/config.json');
    var config = await res.json();
    return config;
}

export const getRequest = (url) => {
    return new Promise(async (resolve, reject) => {
        console.log(url);
        window.ToggleLoader();
        axios({
            method: "get",
            url: `${localStorage.getItem("domain")}${url}`,
            headers: {
                "dataviztoken": localStorage.getItem("token"),
                "Authorization": `Bearer ${localStorage.getItem("jwt")}`

            }
        })
            .then((result) => {
                window.ToggleLoader();
                resolve(result);
            })
            .catch(function (err) {
                if (err?.response?.status === 401) {
                    window.sessionExpired();
                }
                window.ToggleLoader();
                reject(err)
            })
    })
}

export const postRequest = (url, data) => {
    return new Promise(async (resolve, reject) => {
        console.log(url);
        window.ToggleLoader();
        axios({
            method: "post",
            url: `${localStorage.getItem("domain")}${url}`,
            data: data,
            headers: {
                'Content-Type': 'application/json',
                "dataviztoken": localStorage.getItem("token"),
                "Authorization": `Bearer ${localStorage.getItem("jwt")}`
            }
        })
            .then((result) => {
                window.ToggleLoader();
                resolve(result);
            })
            .catch(function (err) {
                if (err?.response?.status === 401) {
                    window.sessionExpired();
                }
                window.ToggleLoader();
                reject(err)
            })
    })
}

// export const readJsonFromPresignedUrl = (url) => {
//     new Promise((resolve, reject) => {
//         window.ToggleLoader();
//         axios({
//             method: "get",
//             url: url
//         })
//             .then((result) => {
//                 window.ToggleLoader();
//                 const json = result.data;
//                 resolve(json);
//             })
//             .catch(function (err) {
//                 window.ToggleLoader();
//                 reject(err)
//             })
//     })
// }

export const readJsonFromPresignedUrl = async (url) => {
    try {
        const response = await axios.get(url);
        const json = response.data;
        return json;
    } catch (error) {
        console.error(error);
        window.sessionExpired();
        return error;
    }
};