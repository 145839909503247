import React, { useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';

const CustomAlert = ({ show, hideAlert, variant, heading, body, showCloseBtn, className, closeAfter }) => {
    useEffect(() => {
        if (closeAfter) {
            setTimeout(() => {
                hideAlert()
            }, closeAfter);
        }
    }, [closeAfter])
    return (
        <>
            <Alert className={className} show={show} variant={variant} onClose={() => hideAlert()} dismissible = {showCloseBtn}>
                {heading && <Alert.Heading>{heading}</Alert.Heading>}
                {body && <p>{body}</p>}
            </Alert>
        </>
    );
}
export default CustomAlert;