import React, { useEffect } from 'react';


const UpdateRevewSrcRadioBtn = ({ updateSource, setUpdateSource, selectedStatement }) => {
    useEffect(() => {
        if (selectedStatement)
            selectedStatement.updateSource ? setUpdateSource(true) : setUpdateSource(false);
    }, [selectedStatement])

    const UpdateStatus = (e) => {
        e.currentTarget.value === 'true' ? setUpdateSource(true) : setUpdateSource(false);
    }


    return (
        <>
            <div id="Radio-Buttons" className="mb-3">
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="UpdateExisting"
                        value="true" onChange={UpdateStatus} id="UpdateInstanceRadio" checked={updateSource} />
                    <label className="form-check-label float-start" htmlFor="UpdateInstanceRadio">
                        Update Source
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="UpdateExisting"
                        value="false" onChange={UpdateStatus} id="CreateNewInstanceRadio"
                        checked={!updateSource} />
                    <label className="form-check-label float-start" htmlFor="CreateNewInstanceRadio">
                        Create New Source
                    </label>
                </div>
            </div>
        </>
    );
}

export default UpdateRevewSrcRadioBtn;